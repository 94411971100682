import { getComponentOptions } from '@dundle/utils/vue';

export default {
    methods: {
        setBreadcrumbs() {
            const options = getComponentOptions(this.$vnode);

            if ('breadcrumb' in options) {
                this.$store.dispatch('breadcrumb/setItems', options.breadcrumb.call(this, { app: this }, this));
            } else {
                this.showLayoutBreadcrumbs = false;
                if (process.env.NODE_ENV !== 'production') {
                    console.warn('Component does not contain an option called "breadcrumb"');
                }
            }

            if (options.meta && options.meta.breadcrumb === false) {
                this.$store.dispatch('breadcrumb/showLayoutBreadcrumbs', false);
            } else {
                this.$store.dispatch('breadcrumb/showLayoutBreadcrumbs', true);
            }
        },
    },
    created() {
        this.setBreadcrumbs();
    },

    updated() {
        this.setBreadcrumbs();
    },
};
