<template>
    <UISearchDropdown
        id="product-dropdown"
        v-model="selectedItem"
        class="product-dropdown"
        :default-item="selectedItem"
        :items="items"
        :placeholder="translate(`search.${type}`)"
        :search-index="[`value`, `name`]"
    >
        <template #label>
            <div class="dropdown-span sr-only">
                {{ translate(`search.${type}`) }}
            </div>
        </template>

        <template #button-content>
            <div>
                <UIFlag class="mr-2 flag-dropshadow" aria-hidden="true" :code="selectedItem.value"></UIFlag>
                <span class="text-dark">{{ selectedTitle }}</span>
            </div>
        </template>

        <template #popup-label>
            <slot name="dropdown-label" />
        </template>

        <template #option-content="{ item }">
            <UIFlag class="mr-2 flag-dropshadow" aria-hidden="true" :code="item.value"></UIFlag>
            <span class="option-title">{{ type === 'region' ? item.name : item.value.toUpperCase() }}</span>
        </template>
    </UISearchDropdown>
</template>
<script>
import { UIFlag } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIFlag,
        UISearchDropdown: () => import('@dundle/ui/components/UISearchDropdown'),
    },

    mixins: [TranslationMixin],

    props: ['brand'],

    computed: {
        type() {
            return this.brand.selectedRegion ? 'region' : 'currency';
        },

        alternativeValues() {
            return this.brand.selectedRegion ? this.brand.regionCodes : this.brand.currencyCodes;
        },

        selected() {
            return this.brand.selectedRegion ? this.brand.selectedRegion : this.brand.selectedCurrency;
        },

        selectedItem: {
            get() {
                return {
                    value: this.selected,
                    name: this.translate(`country.${this.selected.toUpperCase()}`),
                };
            },
            set(newValue) {
                this.$emit('select', newValue.value);
            },
        },

        items() {
            return this.alternativeValues.map(item => {
                const name = this.translate(
                    this.type === 'region' ? `country.${item.toUpperCase()}` : item.toUpperCase()
                );
                if (name) {
                    return {
                        value: item,
                        name,
                    };
                }
                const enName = this.translateIn('en', `country.${item.toUpperCase()}`);
                return {
                    value: item,
                    name: enName,
                };
            });
        },

        selectedTitle() {
            return this.type === 'region' ? this.selectedItem.name : this.selectedItem.value.toUpperCase();
        },
    },
};
</script>

<style lang="scss" scoped>
.country-dropdown:not(:focus) {
    outline: none;
}
</style>

<i18n src="@dundle/locale/data/currencies.json"></i18n>
<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n src="@dundle/locale/data/search.json"></i18n>
