<template>
    <div class="official-paysafe-distributor">
        <div class="content-container d-flex align-items-center">
            <div class="image">
                <nuxt-resource src="images/brands/badge/watermarks_paysafecard.svg" alt="paysafecard distributor" />
            </div>
            <div class="content">
                <div class="title font-weight-bold">{{ translate('paysafeDistributorTitle') }}</div>
                <div class="text small text-light">{{ translate('paysafeDistributorText') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    mixins: [TranslationMixin],
};
</script>

<style lang="scss" scoped>
.official-paysafe-distributor {
    @media screen and (max-width: 767px) {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-top: 1rem;
        background: $color-grey-1;
        padding-top: 0.66rem;
        border-top: 1px solid $color-grey-3;
    }

    .content-container {
        background: $color-bg;
        border-radius: $border-radius-default;
        padding: 1rem;

        @media screen and (min-width: 768px) {
            border: 1px solid $color-grey-3;
        }
    }

    .image {
        width: 4rem;
        min-width: 4rem;
        margin-right: 0.66rem;
    }
}
</style>

<i18n></i18n>
