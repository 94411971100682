<template>
    <div class="slogan">
        {{ translate('slogan') }} <UIIcon :name="['fa', 'check-circle']" class="icon ml-1"></UIIcon>
    </div>
</template>
<script>
import { UIIcon } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'Slogan',
    components: {
        UIIcon,
    },
    mixins: [TranslationMixin],
};
</script>
<style lang="scss" scoped>
.slogan {
    font-family: 'Roboto Slab', serif;
    font-size: 1.0666666666666667rem;
}

.icon {
    color: $color-validation;
    font-size: 1.25rem !important;
}
</style>

<i18n></i18n>
