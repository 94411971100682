<template>
    <div class="x-rating-stars d-flex">
        <UIIcon v-for="i in fullStars" :key="i" class="rating-star" name="star"></UIIcon>

        <div v-if="hasRemainingStar" class="remaining-stars-wrapper">
            <UIIcon name="star" class="empty-star"></UIIcon>
            <div class="fill-star" :style="ramainingStarStyle">
                <UIIcon name="star"></UIIcon>
            </div>
        </div>
    </div>
</template>

<script>
import { UIIcon } from '@dundle/ui/components';

export default {
    components: {
        UIIcon,
    },
    props: {
        rating: Number,
    },
    computed: {
        fullStars() {
            return Math.floor(this.rating);
        },

        remainingStarWidth() {
            return 5 - this.fullStars;
        },

        hasRemainingStar() {
            return this.remainingStarWidth > 0;
        },

        ramainingStarStyle() {
            const regex = /.\d$/;
            let remaining = 0;
            if ((remaining = regex.exec(this.rating) !== null)) {
                remaining = regex.exec(this.rating)[0].replace('.', '');
            }

            return {
                width: remaining * 10 + '%',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.x-rating-stars {
    position: relative;
    .rating-star {
        margin-right: 0.075rem;
        color: $color-star;

        &:last-of-type {
            margin-right: 0;
        }
    }
    .Icon {
        position: relative;
    }
    .remaining-stars-wrapper {
        position: relative;
        width: 1.125em;
        line-height: 1;
        .empty-star {
            color: $color-grey-3;
        }

        .fill-star {
            overflow: hidden;
            position: absolute;
            top: 0;
            bottom: 0;
            color: $color-star;
        }
        .Icon {
            top: 0;
            bottom: 0;
            position: absolute;
        }
    }
}
</style>
