<template>
    <div class="feature-container">
        <div v-if="showFeatures" class="feature delivery-feature mb-3">
            <header><UIIcon :name="['fa', 'check-circle']" color="#0bb40b" /> {{ translate('delivery.title') }}</header>
            <p class="text-light">
                {{ translate('delivery.content') }}
            </p>
        </div>

        <div v-if="showFeatures && showGiftpaper" class="feature gift-feature mb-3">
            <header><UIIcon :name="['fa', 'check-circle']" color="#0bb40b" /> {{ translate('gift.title') }}</header>
            <p class="text-light">
                {{ translate('gift.content') }}
            </p>
        </div>

        <div
            v-if="showFeatures"
            class="feature payment-feature"
            :class="showGiftpaper || !showProductReviews ? 'mb-3' : 'mb-0'"
            @click.stop.prevent="showModal({ name: 'footer-payment-methods', isLargeModal: true })"
        >
            <header><UIIcon :name="['fa', 'check-circle']" color="#0bb40b" /> {{ translate('payment.title') }}</header>
            <p class="text-light">
                {{ translate('payment.content', { value: Math.floor(methods.length / 10) * 10 }) }}
            </p>
            <div class="d-flex justify-content-between mt-2">
                <nuxt-resource
                    v-for="(method, index) in methods.slice(0, 8)"
                    :key="`${method.id}-${index}`"
                    lazy
                    :src="`images/payment/${method.id}.svg`"
                    :alt="method.description"
                    :height="25"
                    :width="25"
                />
            </div>
        </div>

        <div v-if="showProductReviews" ref="scrollAnchor" class="feature review-feature mb-3">
            <header style="border-bottom: 1px solid #e0e0e0" class="pb-2 mb-3">
                <UIIcon :name="['fa', 'check-circle']" color="#0bb40b" /> {{ translate('reviews.title') }}
            </header>
            <slot name="reviews"></slot>
        </div>

        <slot />
    </div>
</template>

<script>
import { UIIcon } from '@dundle/ui/components';
import { mapGetters, mapActions } from 'vuex';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIIcon,
    },
    mixins: [TranslationMixin],

    props: {
        showProductReviews: {
            type: Boolean,
            default: false,
        },

        showFeatures: {
            type: Boolean,
            default: true,
        },
        showGiftpaper: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            methods: 'data/getMethods',
        }),
    },
    methods: {
        ...mapActions({
            showModal: 'ui/showModal',
        }),
    },
};
</script>

<style lang="scss" scoped>
.feature-container {
    position: relative;
    padding: 1rem;

    margin: 0 -15px;

    .feature {
        background: $color-card-bg;
        border: 1px solid $color-grey-3;
        padding: 1rem;
        border-radius: $border-radius-default;
        cursor: pointer;

        header {
            font-weight: bold;
            color: $color-text-bold;
        }

        p {
            font-size: 0.85rem;
            margin: 0;
        }
    }

    ::v-deep .x-box .box-content {
        background: $color-card-bg;
    }

    .payment-method-image {
        &.none {
            opacity: 0;
        }
    }
}
</style>

<i18n></i18n>
